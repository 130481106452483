var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"markdown-body text-center pb-12"},[_c('div',[(_vm.publications)?_c('v-container',[_c('v-row',[_c('v-card',{staticClass:"px-5 py-5 mt-10 text-center",staticStyle:{"width":"100% !important"}},[_c('h1',[_vm._v("ICJIA Publications")]),_c('v-card-title',{staticClass:"mb-5"},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"text-center",attrs:{"headers":_vm.headers,"items":_vm.publications,"search":_vm.search,"show-expand":"","item-key":"id","single-expand":_vm.singleExpand,"expanded":_vm.expanded,"loading":_vm.tableLoading,"dense":"","page":_vm.page,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"footer-props":{
              'items-per-page-options': [100, 150, 200, 250, 500],
            },"items-per-page":150,"id":"pubTable"},on:{"update:expanded":function($event){_vm.expanded=$event},"click:row":_vm.clicked,"update:page":function($event){_vm.page=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.publicationDate",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticStyle:{"width":"90px","font-size":"14px","font-weight":"700","color":"#555"}},[_vm._v(" "+_vm._s(_vm._f("dateFormatAlt")(item.publicationDate))+" ")])]}},{key:"item.title",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"my-2"},[_c('span',{},[(_vm.isItNew(item))?_c('v-chip',{staticClass:"mr-2",staticStyle:{"margin-top":"0px"},attrs:{"label":"","x-small":"","color":"#0D4474"}},[_c('span',{staticStyle:{"color":"#fff !important","font-weight":"400"}},[_vm._v(" NEW! ")])]):_vm._e(),_c('strong',[_vm._v(_vm._s(item.title))])],1)])]}},{key:"item.pubType",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"my-2"},[_c('span',{},[_vm._v(_vm._s(_vm.getPublicationType(item.pubType)))])])]}},{key:"item.articleURL",fn:function(ref){
            var item = ref.item;
return [(item.localArticlePath && item.localArticlePath.length)?_c('div',{staticClass:"my-2"},[_c('span',{},[_c('v-btn',{attrs:{"outlined":"","x-small":"","color":"blue darken-4"},on:{"click":function($event){return _vm.registerArticleView(item)}}},[_vm._v("Web Article")])],1)]):_vm._e()]}},{key:"item.fileURL",fn:function(ref){
            var item = ref.item;
return [(item.fileURL && item.fileURL.length)?_c('div',{staticClass:"my-2"},[(item.pubType !== 'application')?_c('span',[_c('v-btn',{attrs:{"href":item.fileURL,"target":"_blank","x-small":""},on:{"click":function($event){return _vm.registerDownload(item)}}},[_vm._v("Download PDF"),_c('v-icon',{attrs:{"right":""}},[_vm._v("download")])],1)],1):_c('span',[_c('v-btn',{attrs:{"x-small":""}},[_vm._v("Open Application"),_c('v-icon',{attrs:{"right":""}},[_vm._v("open_in_new")])],1)],1)]):_vm._e()]}},{key:"expanded-item",fn:function(ref){
            var headers = ref.headers;
            var item = ref.item;
return [_c('td',{staticStyle:{"padding":"0 !important","margin":"0 !important"},attrs:{"colspan":headers.length}},[_c('PublicationCard',{attrs:{"item":item}})],1)]}}],null,false,2925086482)})],1),_c('div',{staticClass:"mt-5",staticStyle:{"font-size":"12px","text-align":"center"}},[_vm._v(" Individual publications are also available for download from the ICJIA Document Archive: "),_c('a',{attrs:{"href":"https://archive.icjia.cloud","target":"_blank"}},[_vm._v("https://archive.icjia.cloud")])])],1)],1):_c('v-container',[_c('v-row',[_c('v-col',[_c('Loader',{attrs:{"loaderType":"skeleton"}})],1)],1)],1),_c('div',{staticStyle:{"height":"200px"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }